.AppBar{
    position:absolute;
    top:0;
    left:0;
    right:0;
    height:109px;
    box-shadow: 10px 5px 10px #efefef;
    z-index: 1;
}

.AppLogo {
    position: absolute;
    left:0;
    top:0;
    height:109px;
}

.AppTitle {
    position: absolute;
    display: block;
    left: 220px;
    top:10px;
    font-weight:500;
    font-size:15px;
    font-family: Inter;
    font-style: normal;
    line-height: 20px;
    vertical-align: center;
    color:#58585A;
    height: 50px;
    border-left: 2px solid #7ab9e5;
    padding-top: 24px;
    padding-left: 16px;
}

.AppTolbar {
    position: absolute !important;
    top:20px;
    right: 0;
    background-color: inherit !important;
}

.AppLogutButton {
    color:red;
    height: 44px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color:#58585A !important;
}

.AppLogutButton img {
    margin-left: 10px;
    height: 44px;
    width: 44px;
}
