.MainMenu {
  position: absolute;
  display: block;
  top: 109px;
  left: 0;
  bottom: 0;
  max-width: 377px;
  min-width: 50px;
  padding-top: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f8f8fa;
}

.TogleMenuButton {
  position: absolute;
}

div.MuiListItemIcon-root {
  margin-right: 5px;
}

.MainMenu .MuiTypography-root {
  font-family: Manrope !important;
  /* font-size:14px !important; */
  /* font-weight: 500 !important; */
  line-height: 18px !important;
}

.DashboardsMenu {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.DashboardsMenu div {
  margin-left: 10px;
}

.DashboardsMenu div .MuiTypography-root {
  font-family: Manrope;
  font-size: 12px !important;
  font-weight: 500;
  line-height: 18px;
  margin-left: -10px;
}

.MainMenu .MuiBox-root {
  margin-top: 20px;
}

.MainMenu .MuiListItemButton-root {
  height: 53px;
}
